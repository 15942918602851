import { useEffect } from "react"

// Components
import Layout from "components/Layout"
import Header from "components/Header"
import Footer from "components/Footer"
import { Likert } from "components/Likert"
import Loader from "components/Loader"
import If from "@mobi/components/If"
import Marketplace from "components/Marketplace"
import { BackButton } from "components/BackButton"

// Data
import { marketplaceCardItems } from "./dataContent"

// Hooks
import useDeniedProposal from "./useDeniedProposal"

// Style
import "./index.scss"

// Utils
import { navigateWithQueryString } from "utils/browser"

const DeniedProposal = () => {
  const { stepData, isLoading, isClosedFlow } = useDeniedProposal()

  const { externalOffer } = stepData

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout className={`-form -denied`}>
      <div className="denied-proposal">
        <Header />
        <If
          condition={isLoading}
          renderIf={<Loader />}
          renderElse={
            <>
              <div className="denied-proposal__container">
                {!isClosedFlow && (
                  <BackButton onClick={() => navigateWithQueryString("/")} />
                )}
                <div className="denied-proposal__klubi">
                  <iframe
                    src={externalOffer}
                    width="100%"
                    height="680px"
                    frameBorder="0"
                    seamless="seamless"
                    marginHeight="0"
                    marginWidth="0"
                  ></iframe>
                </div>
                <div className="denied-proposal__wrapper">
                  <div className="denied-proposal__likert">
                    <Likert />
                  </div>
                  <Marketplace
                    cardItems={marketplaceCardItems}
                    currentStep="denied-proposal"
                  />
                </div>
                <br />
                <br />
                <br />
              </div>

              <Footer />
            </>
          }
        />
      </div>
    </Layout>
  )
}

export default DeniedProposal
