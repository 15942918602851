import { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"

// Utils
import getQueryStringFromObject from "utils/getQueryStringFromObject"
import { getUrlParamAll, getUUIDFromUrl } from "utils/browser"
import { getFromSessionStorage } from "utils/storage"

//Services
import services from "services"

// Tracking
import analytics from "@mobi/libraries/analytics"

// Contexts
import { Context } from "contexts/simulation"
import { navigateWithQueryString } from "../../utils/browser"

const useDeniedProposal = () => {
  const [stepData, setStepData] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const { simulation, setSimulation } = useContext(Context)

  const isClosedFlow = getFromSessionStorage({ name: "isClosedFlow" }) || false

  const uuid = getUUIDFromUrl()

  useEffect(() => {
    const startSimulationStep = async () => {
      const response = await services.simulation.getSimulation()
      setStepData(response)
      setIsLoading(false)

      analytics.track("pageLoad", {})
      analytics.track("simulationFail", { deals: response.deals })
    }

    startSimulationStep()
  }, [])

  const onNext = async (vehicle) => {
    setIsLoading(true)
    const updatedSimulation = await services.deal
      .retry(vehicle, {
        sourceUrl: window?.location?.href,
      })
      .catch(() => {
        return navigateWithQueryString("/indisponivel/", {
          leadUUID: uuid.value,
        })
      })

    if (updatedSimulation?.status === 200) {
      const data = updatedSimulation?.data

      setSimulation({
        ...simulation,
        ...data,
      })

      const { leadUUID } = data
      const currentUuid = getUUIDFromUrl()
      const uuid = currentUuid ? currentUuid.value : leadUUID
      const currentParams = getUrlParamAll()
      const query = getQueryStringFromObject({
        ...currentParams,
        leadUUID: uuid,
      })
      return navigate(`/formulario/${query}`)
    }
  }

  return {
    stepData,
    isLoading,
    onNext,
    isClosedFlow,
  }
}

export default useDeniedProposal
